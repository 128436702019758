import React, { useContext, ReactElement, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { OverviewScreen } from '../../screens/OverviewScreen';
import { ConfigContext } from '../../config';

interface FlowContainerProps {
  children: ReactElement | string;
  showOverviewOnMobile?: boolean;
  onStepClick: Function;
}

interface ScreensHolderProps {
  isActiveOnMobile: boolean;
}

export const FlowContainer: React.FC<FlowContainerProps> = function (props) {
  const { children, showOverviewOnMobile, onStepClick } = props;
  const { chapters, currentScreen } = useContext(ConfigContext);
  const location = useLocation();

  return (
    <StyledFlowContainer>
      <OverviewHolder isActiveOnMobile={!showOverviewOnMobile}>
        <TransitionGroup>
          <CSSTransition key={location.pathname.split('/')[1]} classNames="fade" timeout={300} unmountOnExit>
            <Switch location={location}>
              {chapters.map((chapter, chapterIndex) => (
                <Route key={`chapter${chapterIndex}`} path={`/chapter${chapterIndex}`}>
                  <OverviewScreen onStepClick={onStepClick} currentChapter={currentScreen} />
                </Route>
              ))}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </OverviewHolder>
      <ScreensHolder isActiveOnMobile={!showOverviewOnMobile}>{children}</ScreensHolder>
    </StyledFlowContainer>
  );
};

FlowContainer.defaultProps = {
  showOverviewOnMobile: true,
};

const StyledFlowContainer = styled.div`
  width: 800px;
  height: 680px;
  background: ${(props) => props.theme.color.background};
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  position: relative;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: auto;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
`;

const OverviewHolder = styled.div<ScreensHolderProps>`
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.color.additional};

  @media ${(props) => props.theme.breakpoints.tablet} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    ${(props) => props.isActiveOnMobile
      && css`
        opacity: 0;
        pointer-events: all;
      `}

    ${(props) => !props.isActiveOnMobile
      && css`
        overflow: auto;
      `}
  }

  & > div {
    display: contents;
  }
`;

const ScreensHolder = styled.div<ScreensHolderProps>`
  flex: 1;
  position: relative;
  background: ${(props) => props.theme.color.white};
  transition: all 0.2s ease;

  @media ${(props) => props.theme.breakpoints.tablet} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;

    ${(props) => props.isActiveOnMobile
      && css`
        opacity: 1;
        pointer-events: all;
      `}
  }
`;
